import axios from 'axios'
import { useEffect, useState } from 'react'
import ResultsListItem from '../appointments/ResultsListItem'
import { IonList, IonSpinner } from '@ionic/react'

export default function DetailsHistory({ customer_id, businessId, checkCustomer, locations, locationId }) {
  let [history, setHistory] = useState({ loading: false, data: null, error: null })
  async function getHistory() {
    if (customer_id) {
      try {
        setHistory({ ...history, ...{ loading: true } })
        let response = await axios.get(`/customer_v2/appointments?customerId=${customer_id}`)

        setHistory({ loading: false, data: response.data, error: null })
      } catch (error) {
        console.error('Error fetching history:', error) // Log the error for debugging
        setHistory({ loading: false, data: null, error: { message: error.message || 'Failed to fetch history.' } })
      }
    } else {
      console.warn('Customer ID is not defined') // Log if customer_id is not available
    }
  }

  useEffect(() => {
    getHistory()
  }, [customer_id])

  if (!customer_id) return 'loading...'
  return (
    <>
      {history?.loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IonSpinner name='dots' />
        </div>
      ) : (
        <IonList inset={true}>
          {history?.data?.map((row) => (
            <ResultsListItem row={row} businessId={businessId} />
          ))}
        </IonList>
      )}
    </>
  )
}
