import { useEffect, useState } from 'react'
import axios from 'axios'
import { IonCol, IonGrid, IonIcon, IonRouterLink, IonRow, IonSpinner } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'
import CreateSubscriptionModal from '../subscriptions/createSubscriptionModal'
import { cancelSubscriptionOptions } from '../StaticData/StaticData'

export default function SubscriberInfo({ fromCustomer, customer, setIsSubscribed = () => {}, refreshData = () => {}, reloadTrigger }) {
  const [subscription, setSubscription] = useState({ data: null, loading: false, error: null })
  const [createSubscriptionModalOpen, setCreateSubscriptionModalOpen] = useState(false)
  const getCustomerSubscription = async () => {
    if (customer?._id) {
      try {
        setSubscription({ data: subscription.data, loading: true, error: null })
        const response = await axios.get(`/subscription/byCustomerId?id=${customer._id}&source=subscriberinfo`)
        setSubscription({ data: response.data, loading: false, error: null })
        setIsSubscribed(response?.data?.isSubscribed || false)
      } catch (error) {
        console.error('Error fetching customer subscription:', error)
        setSubscription({
          data: null,
          loading: false,
          error: 'There was an issue getting your data',
        })
      } finally {
        // You can add any cleanup or additional logic here if needed
      }
    }
  }

  useEffect(() => {
    getCustomerSubscription()
  }, [customer?._id, reloadTrigger])

  function localRefreshData() {
    getCustomerSubscription()
    refreshData()
  }
  let reason = false

  let latestCanceledSubscription = false
  if (customer?.subscription) {
    latestCanceledSubscription = customer?.subscription
  }

  if (latestCanceledSubscription) {
    const reasonItem = cancelSubscriptionOptions.find(
      (subscriptionItem, subscriptionIndex) => subscriptionItem?.value === latestCanceledSubscription?.cancellationReason
    )

    if (reasonItem) {
      reason = reasonItem.label
    }
  }

  return (
    <div style={{ padding: 0, margin: 0 }}>
      {subscription?.loading ? <IonSpinner name='dots' /> : null}
      {subscription?.data?.isSubscribed ? (
        <IonRouterLink
          routerLink={
            subscription?.data?.isCorporateDeal
              ? `/core/corporateDeals/${subscription?.data?.corporatePackageDeal?._id}`
              : `/core/subscriptions/${subscription?.data?.subscription?._id}`
          }
        >
          <IonGrid style={{ margin: 0, marginLeft: -10, color: 'black' }}>
            <IonRow style={{ margin: 0 }}>
              <IonCol size='auto'>
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 45,
                    width: 45,
                    background: 'linear-gradient(to top right, #FFD700, #FFDF00, #FFEA00)',
                    borderRadius: 8,
                  }}
                >
                  <h1 style={{ margin: 0, marginTop: -4 }}>∞</h1>
                </div>
              </IonCol>
              <IonCol>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <h5 style={{ margin: 0, marginTop: 4 }}>{subscription?.data?.subscription?.name} Package</h5>
                  <h6 style={{ margin: 0, fontWeight: '600', fontSize: 14 }}>{subscription?.data?.isCorporateDeal ? 'Corporate ' : null}Subscriber</h6>
                </div>
              </IonCol>
              <IonCol size='auto'>
                <div
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 45,
                  }}
                >
                  <IonIcon icon={chevronForwardOutline} style={{ fontSize: 21, color: '#bfbfbf' }} />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonRouterLink>
      ) : (
        <>
          {subscription?.loading ? null : (
            <IonGrid style={{ margin: 0, marginLeft: -10, color: 'black' }}>
              <IonRow
                onClick={() => {
                  setCreateSubscriptionModalOpen(true)
                }}
                style={{
                  cursor: 'pointer',
                  margin: 0,
                  paddingBottom: '5px',
                  borderBottom: customer?.subscription && fromCustomer ? '1px solid #cacaca' : '',
                }}
              >
                <IonCol size='auto'>
                  <div
                    style={{
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 30,
                      width: 30,
                      background: 'linear-gradient(to top right, black, grey)',
                      borderRadius: 4,
                    }}
                  >
                    <h4 style={{ margin: 0, marginTop: -3 }}>∞</h4>
                  </div>
                </IonCol>
                <IonCol>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: 9,
                    }}
                  >
                    <h6 style={{ margin: 0, marginTop: -3, marginBottom: 0 }}>Not Subscribed</h6>
                    <p
                      style={{
                        fontSize: 12,
                        margin: 0,
                        fontWeight: '500',
                        marginBottom: -2,
                        color: colors.primaryBlue,
                      }}
                    >
                      Create Subscription
                    </p>
                  </div>
                </IonCol>
                <IonCol size='auto'>
                  <div
                    style={{
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 30,
                    }}
                  >
                    <IonIcon icon={chevronForwardOutline} style={{ fontSize: 21, color: '#bfbfbf' }} />
                  </div>
                </IonCol>
              </IonRow>

              {customer?.subscription && fromCustomer && (
                <IonRouterLink
                  routerLink={
                    subscription?.data?.isCorporateDeal
                      ? `/core/corporateDeals/${latestCanceledSubscription?._id}`
                      : `/core/subscriptions/${latestCanceledSubscription?._id}`
                  }
                  color={'black'}
                >
                  <IonRow
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IonCol size='auto'>
                      <span style={{ padding: '5px 10px', background: '#e7e7e7', borderRadius: '5px' }}>Canceled</span>
                    </IonCol>
                    <IonCol>
                      <h4 style={{ margin: 0, fontWeight: 'bold' }}>{latestCanceledSubscription.name}</h4>

                      {reason && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                          }}
                        >
                          <h6 style={{ margin: '0' }}>{reason}</h6>
                        </div>
                      )}
                    </IonCol>
                  </IonRow>
                </IonRouterLink>
              )}
            </IonGrid>
          )}
        </>
      )}
      <CreateSubscriptionModal
        isOpen={createSubscriptionModalOpen}
        setIsOpen={setCreateSubscriptionModalOpen}
        customer={customer}
        onSuccess={localRefreshData}
      />
    </div>
  )
}
