import React, { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToast, IonToolbar } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'
import FeaturesList from './enrolledEmailsList'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'

export default function Details({ match }) {
  let corporateDeal_id = match?.params?.id
  const [value, setValue] = React.useState(0)
  const [corporateDeal, setCorporateDeal] = useState()
  const [corporateDealTemp, setCorporateDealTemp] = useState()
  const [corporateDealChanged, setCorporateDealChanged] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  // Function to show toast messages
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const getCorporateDeal = async () => {
    try {
      const response = await axios.get(`/corporateDeal?id=${corporateDeal_id}`)

      if (response.status === 200) {
        setCorporateDeal(response.data)
        setCorporateDealTemp(response.data)
      }
    } catch (error) {
      console.error('Error fetching corporate deal:', error) // Log error for debugging
    } finally {
    }
  }

  useEffect(() => {
    getCorporateDeal()
  }, [corporateDeal_id])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const updateCorporateDeal = (value) => {
    setCorporateDealTemp(value)
    setCorporateDealChanged(true)
  }

  const save = async () => {
    try {
      await axios.patch(`/corporateDeal?id=${corporateDeal_id}`, corporateDealTemp)
      await getCorporateDeal() // Refresh the deal details
      setCorporateDealChanged(false)
      showToastMessage('Corporate deal updated successfully!', 'success')
    } catch (error) {
      showToastMessage('Failed to update corporate deal. Please try again.', 'danger')
    }
  }

  const cancel = () => {
    setCorporateDealTemp(corporateDeal)
    setCorporateDealChanged(false)
  }

  const deleteCorporateDeal = async () => {
    if (window.confirm('Are you sure you want to delete the subscription package?')) {
      try {
        await axios.delete(`/corporateDeal?id=${corporateDeal_id}`)
        showToastMessage('Corporate deal deleted successfully!', 'success')
        history.goBack()
      } catch (error) {
        showToastMessage('Failed to delete corporate deal. Please try again.', 'danger')
      }
    }
  }

  // if (!corporateDeal) return <IonPage><IonContent></IonContent></IonPage>

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{corporateDealTemp ? corporateDealTemp?.name : 'loading...'}</IonTitle>
          {corporateDealChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          message={toast.message}
          color={toast.color}
          duration={2000}
        />
        {corporateDeal ? (
          <StandardContainer>
            <GeneralDetails corporateDeal={corporateDealTemp} updateCorporateDeal={updateCorporateDeal} locationId={locationId} businessData={businessData} />
            <FeaturesList corporateDeal={corporateDealTemp} updateCorporateDeal={updateCorporateDeal} />
            <div style={{ padding: 10, marginTop: 200 }}>
              <IonButton color='warning' fill='solid' expand='block' onClick={deleteCorporateDeal}>
                Delete
              </IonButton>
            </div>
          </StandardContainer>
        ) : (
          <StandardCenterCenter>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <IonSpinner name='dots' />
          </StandardCenterCenter>
        )}
      </IonContent>
    </IonPage>
  )
}
