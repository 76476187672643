import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react'
import { AuthContext } from '../auth'
import { getFormattedMediumDate } from '../utils'
import StandardContainer from '../commonComponents/StandardContainer'
import { checkmarkCircleOutline, closeCircleOutline, menu, storefrontOutline } from 'ionicons/icons'
import TrendChart from './trendsChart'
import { colors } from '../theme/colors'
import MiniHeader from '../commonComponents/MiniHeader'
import LocationStats from './statsCard'
import ProgressChart from './progressChart'
import DefaultMenu from '../commonComponents/SideMenu/DefaultMenu'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import NoPermissionsCard from '../commonComponents/NoPermissionsCard'
import TodayCard from './todayCard'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'
import { AccessControl } from '../AccessControl'

const styles = {
  topCard: { padding: 20, margin: 0, minWidth: 160 },
}

const defaultApptSummaryState = {
  Complete: 0,
  'In Progress': 0,
  'No Show': 0,
  Scheduled: 0,
  Arrived: 0,
}

export default function Dashboard() {
  const { businessData, locationId, userData, selectedLocationData } = useContext(AuthContext)
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  const [appointments, setAppointments] = useState({ data: null, loading: false, error: null, locationId: locationId })
  const [projectedData, setProjectedData] = useState({ data: null, loading: false, error: null, locationId: locationId })
  const [trends, setTrends] = useState({ data: null, loading: false, error: null, locationId: locationId })
  const [projectedValue, setProjectedValue] = useState('--')
  const [currentValue, setCurrentValue] = useState('--')
  const [apptSummary, setApptSummary] = useState(defaultApptSummaryState)
  const [walkinsWaitingList, setWalkinsWaitingList] = useState()
  const [walkinsList, setWalkinsList] = useState()
  const [refreshValue, setRefreshValue] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('Appointments')

  const getAppointmentsList = async () => {
    if (!businessData?._id || !locationId) {
      return
    }
    setAppointments({
      loading: true,
      data: appointments?.locationId === locationId ? appointments.data : null,
      error: null,
      locationId,
    })

    try {
      let response = await axios.get(
        `/appointment_v2/getList?businessId=${businessData._id}&locationId=${locationId}&dateText=${getFormattedMediumDate(new Date())}`
      )
      setAppointments({
        loading: false,
        data: response.data,
        error: null,
        locationId: locationId,
      })

      setApptSummary(calculateApptSummary(response.data))
    } catch (error) {
      setAppointments({
        loading: false,
        data: null,
        error: 'There was an issue fetching the data',
        locationId: locationId,
      })
    }
  }

  const parseApptWaitingList = async () => {
    let wwl = appointments?.data?.filter((a) => a.type === 'Walkin' && (a.status === 'Scheduled' || a.status === 'Arrived'))
    let walkinList = appointments?.data?.filter((a) => a.type === 'Walkin')
    setWalkinsWaitingList(wwl)
    setWalkinsList(walkinList)
  }

  const getTrends = async () => {
    setTrends({
      loading: true,
      data: trends?.locationId === locationId ? trends.data : null,
      error: null,
      locationId,
    })

    try {
      const response = await axios.get(`/dashboard/appointmentTrends?businessId=${businessData?._id}&locationId=${locationId}`)
      setTrends({ data: response.data, loading: false, error: null, locationId: locationId })
    } catch (error) {
      setTrends({ data: null, loading: false, error: error, locationId: locationId })
    }
  }

  const getProjectedData = async () => {
    setProjectedData({
      loading: true,
      data: projectedData?.locationId === locationId && projectedData?.selectedCategory === selectedCategory ? projectedData.data : null,
      error: null,
      locationId,
      selectedCategory,
    })

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1

    try {
      let url = `/dashboard/projectedTrends?businessId=${businessData?._id}&year=${currentYear}&month=${currentMonth}&locationId=${locationId}&category=${selectedCategory}`
      let response = await axios.get(url)
      setProjectedData({
        data: response?.data,
        loading: false,
        error: null,
        locationId,
        selectedCategory,
      })
    } catch (error) {
      setProjectedData({
        data: projectedData.data,
        loading: false,
        error: error,
        locationId,
        selectedCategory,
      })
    }
  }

  useEffect(() => {
    getProjectedData()
  }, [selectedCategory])

  useEffect(() => {
    let sumOfApptThisMonth = projectedData?.data?.thisMonth?.trends?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)

    if (projectedData?.data && appointments.data && selectedCategory === 'Appointments') {
      let pvalue = projectTotalAppointments(
        (sumOfApptThisMonth || 0) +
        (apptSummary?.Total || 0) -
        (apptSummary?.['No Show'] || 0) -
        (apptSummary?.['Canceled'] || 0) -
        (apptSummary?.['Complete'] || 0)
      )
      setProjectedValue(pvalue)
      setCurrentValue(sumOfApptThisMonth)
    } else if (projectedData?.data && selectedCategory === 'Revenue') {
      let pvalue = projectTotalAppointments(sumOfApptThisMonth || 0)
      setProjectedValue(pvalue)
      setCurrentValue(sumOfApptThisMonth)
    } else {
      setProjectedValue('--')
    }
  }, [appointments?.data, projectedData?.data])

  useEffect(() => {
    parseApptWaitingList()
  }, [appointments?.data, locationId])

  useEffect(() => {
    getTrends()
    getAppointmentsList()
    getProjectedData()
  }, [businessData, locationId, refreshValue])

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshValue(Math.random())
    }, 20000)
    return () => clearInterval(interval)
  }, [])

  async function handleRefresh(event) {
    await getTrends()
    await getAppointmentsList()
    getProjectedData()
    event.detail.complete()
  }

  function handleScroll(event) {
    const currentScrollPosition = event.detail.scrollTop
    if (currentScrollPosition < 50) {
      setScrollPosition(currentScrollPosition)
    }
  }

  let totalAppts = (apptSummary?.Total || 0) - (apptSummary?.['No Show'] || 0) - (apptSummary?.['Canceled'] || 0)
  let showStripeAlert = ((selectedLocationData?.paymentProcessorType === "stripeconnect" && !selectedLocationData?.isStripeSetup) || (selectedLocationData?.paymentProcessorType === "envaccount" && !selectedLocationData?.stripeEnvValue)) ? true : false

  return (
    <>
      <DefaultMenu />
      <IonPage id='main-content' mode='ios'>
        <StripeAlert showStripeAlert={showStripeAlert} />


        <div
          style={{
            marginBottom: 0,
            width: '100%',
            height: 50,
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            boxShadow: scrollPosition > 10 ? '0px 1px 3px #ededed' : '',
          }}
        >

          <IonGrid>
            <IonRow>
              <IonCol size='auto'>
                <IonMenuButton>
                  <IonIcon icon={storefrontOutline} style={{ color: 'black' }}></IonIcon>
                </IonMenuButton>
              </IonCol>
              <IonCol>
                <StandardCenterCenter>
                  <h6 style={{ marginTop: 12 }}>{scrollPosition > 10 ? selectedLocationData?.name : null}</h6>
                </StandardCenterCenter>
              </IonCol>
              <IonCol size='auto'>
                <div style={{ width: 50, height: 10 }} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <IonContent fullscreen={true} className='whiteBackground' scrollEvents={true} onIonScroll={handleScroll}>
          <StandardContainer>
            <h6 style={{ marginLeft: 10, marginBottom: -20, marginTop: 10, color: colors.primaryBlue }}>{businessData.name}</h6>
            <h1 size='large' mode='ios' style={{ marginLeft: 10 }}>
              {selectedLocationData?.name}
            </h1>
          </StandardContainer>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <StandardContainer>

            <IonGrid>
              <AccessControl componentName={'dashboard_progress_chart'}>
                <ProgressChart
                  data={projectedData?.data}
                  firstLoad={projectedValue ? true : false}
                  loading={projectedData?.loading}
                  projectedValue={projectedValue}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  currentValue={currentValue}
                />
              </AccessControl>
              {/**TODAY's CHART */}
              <AccessControl componentName={'dashboard_today_card'}>
                <div style={{ marginTop: 10, marginLeft: 10, marginBottom: -10 }}>
                  <MiniHeader title='Today' />
                </div>
                <IonRow>
                  <IonCol>
                    <TodayCard
                      appointments={appointments}
                      apptSummary={apptSummary}
                      totalAppts={totalAppts}
                      walkinsList={walkinsList}
                      walkinsWaitingList={walkinsWaitingList}
                    />
                  </IonCol>
                </IonRow>
              </AccessControl>
              {/**APPOINTMENT TRENDS CHART */}
              <AccessControl componentName={'dashboard_appointment_trends'}>
                <IonRow>
                  <IonCol>
                    <div style={{ marginTop: 10, marginBottom: -10 }}>
                      <MiniHeader title='Trends' />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonCard style={styles.topCard} className='flat'>
                      <h6 style={{ margin: 0, color: 'black' }}>Appointment Trends</h6>
                      <TrendChart
                        data={trends?.data?.trends}
                        statusKeys={['Scheduled', 'In Progress', 'Complete', 'Arrived']}
                        colors={[colors.purple, colors.primaryBlue, colors.green, colors.orange]}
                      />
                    </IonCard>
                  </IonCol>
                </IonRow>
              </AccessControl>

              {/**CANCEL NOSHOW CHARTS STATS */}
              <AccessControl componentName={'dashboard_cancel_no_show_graph'}>
                <IonRow>
                  <IonCol>
                    <IonCard style={styles.topCard} className='flat'>
                      <h6 style={{ margin: 0, color: 'black' }}>Cancels & No Shows</h6>
                      <TrendChart data={trends?.data?.trends} statusKeys={['Canceled', 'No Show']} colors={[colors.orange, colors.grey]} />
                    </IonCard>
                  </IonCol>
                </IonRow>
              </AccessControl>
              {/**DASHBOARD STATS */}
              <AccessControl componentName={'dashboard_stats'}>
                <IonRow>
                  <IonCol>
                    <LocationStats businessId={businessData?._id} />
                  </IonCol>
                </IonRow>
              </AccessControl>
            </IonGrid>


          </StandardContainer>
        </IonContent>
      </IonPage>
    </>
  )
}

function StripeAlert({ showStripeAlert }) {
  if (!showStripeAlert) return null

  return (
    <div style={{
      display: 'flex',
      height: 70,
      zIndex: 1000,
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: showStripeAlert ? 'green' : 'red',
      color: 'white',
    }}>
      <StandardCenterCenter>
        <h5>{showStripeAlert ? <IonIcon icon={checkmarkCircleOutline} style={{ color: 'green' }}></IonIcon> : <IonIcon icon={closeCircleOutline} style={{ color: 'white', marginBottom: -2 }}></IonIcon>} {!showStripeAlert ? 'Billing Setup Complete' : 'Billing Setup Incomplete'}</h5>
        {!showStripeAlert ? "True" : "False"}
      </StandardCenterCenter>
    </div>
  )
}

const getTodaysDate = () => {
  const today = new Date()
  const day = String(today.getDate()).padStart(2, '0')
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const year = today.getFullYear()

  return `${month}-${day}-${year}`
}

function projectTotalAppointments(sumOfApptThisMonth = 0) {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const daysPassed = currentDate.getDate()

  const countSundays = (year, month, totalDays) => {
    let sundays = 0
    for (let day = 1; day <= totalDays; day++) {
      if (new Date(year, month, day).getDay() === 0) {
        sundays++
      }
    }
    return sundays
  }

  const sundaysPassed = countSundays(currentYear, currentMonth, daysPassed)
  const totalSundays = countSundays(currentYear, currentMonth, new Date(currentYear, currentMonth + 1, 0).getDate())

  const adjustedDaysPassed = daysPassed - sundaysPassed
  const adjustedTotalDays = new Date(currentYear, currentMonth + 1, 0).getDate() - totalSundays

  const averageDailyAppointments = sumOfApptThisMonth / adjustedDaysPassed
  const projectedTotal = averageDailyAppointments * adjustedTotalDays

  return Math.round(projectedTotal)
}

const calculateApptSummary = (data) => {
  let d = {
    Complete: 0,
    'In Progress': 0,
    'No Show': 0,
    Scheduled: 0,
    Arrived: 0,
    Total: 0,
    Canceled: 0,
  }
  for (let a of data) {
    d[a.status]++
    d['Total']++
  }
  return d
}
