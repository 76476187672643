import { IonCheckbox, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import { roles } from '../StaticData/StaticData'
import axios from 'axios'
import { AccessControl } from '../AccessControl'
export default function BusinessPermissions({ user, refreshUser }) {
  const { businessData, locationData } = useContext(AuthContext)
  const [updatingPermissions, setUpdatingPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [selectedPermissions, setSelectedPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [toastState, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color) => {
    setToast({
      isOpen: true,
      message,
      color,
    })
  }
  const handleupdatingPermissions = (value, permission) => {
    setUpdatingPermissions({
      ...updatingPermissions,
      [permission]: value,
    })
  }

  const handlePermission = async (permission) => {
    const updatedPermissions = { ...selectedPermissions }
    Object.keys(updatedPermissions).forEach((key) => {
      updatedPermissions[key] = key === permission
    })

    setSelectedPermissions(updatedPermissions)
    handleupdatingPermissions(true, permission)

    let userBusiness = JSON.parse(JSON.stringify([...user.businesses]))
    const businessIndex = userBusiness.findIndex((business) => business._id === businessData?._id)

    if (businessIndex !== -1) {
      userBusiness[businessIndex].locations.forEach((location) => {
        Object.keys(location.entitlements).forEach((key) => {
          location.entitlements[key] = key === permission
        })
      })

      const data = {
        _id: user?._id,
        email: user?.email,
        businesses: userBusiness,
      }

      try {
        let response = await axios.patch(`/user`, data)
        if (response && response.status === 200) {
          refreshUser()
          handleupdatingPermissions(false, permission)
        }
      } catch (error) {
        showToastMessage('Failed to update permissions. Please try again.', 'danger')
      }
    }

    setTimeout(() => {
      handleupdatingPermissions(false, permission)
    }, 1000)
  }

  const updateData = () => {
    if (user && user._id) {
      const locationBusiness = user.businesses.find((item) => item._id === businessData?._id)

      if (locationBusiness) {
        const isAdminTrueInAllLocations = locationBusiness.locations.every((location) => location?.entitlements?.admin || false)
        const isManagerTrueInAllLocations = locationBusiness.locations.every((location) => location?.entitlements?.manager || false)
        const isProfessionalTrueInAllLocations = locationBusiness.locations.every((location) => location?.entitlements?.professional || false)

        const updatedSelectedPermissions = { ...selectedPermissions }
        updatedSelectedPermissions.admin = isAdminTrueInAllLocations
        updatedSelectedPermissions.manager = isManagerTrueInAllLocations
        updatedSelectedPermissions.professional = isProfessionalTrueInAllLocations

        setSelectedPermissions(updatedSelectedPermissions)
      }
    }
  }
  useEffect(() => {
    updateData()
  }, [user])
  useEffect(() => {
    updateData()
  }, [])

  return (
    <AccessControl componentName={'business_permissions'}>
      <IonGrid>
        <IonRow style={{ display: 'flex', alignItems: 'end' }}>
          <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size='large' />
          <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>Business Permissions</h6>
        </IonRow>
        <IonRow
          style={{
            padding: '10px',
            backgroundColor: '#ffd1d1',
            borderRadius: '10px',
            color: '#d60000',
            marginTop: '1rem',
          }}
        >
          <IonLabel>WARNING! Any Permissions set here will apply to every location regardless of what the location permissions are.</IonLabel>
        </IonRow>
        <IonRow>
          {roles.map((item) => (
            <IonRow key={item.value} style={{ width: '100%' }}>
              <IonCol style={{ width: '100%' }}>
                <h6 style={{ margin: '5px', fontWeight: 'bold', color: 'black', fontSize: '1rem' }}>{item.role}</h6>
                <IonRow onClick={() => handlePermission(item.value)} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <IonCol size='10'>
                    <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                  </IonCol>
                  <IonCol size='2' style={{ display: 'flex', justifyContent: 'end' }}>
                    {updatingPermissions[item.value] ? (
                      <IonSpinner name='lines' />
                    ) : (
                      <IonCheckbox style={{ height: '1.5rem', width: '1.5rem' }} checked={selectedPermissions[item.value]} />
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          ))}
        </IonRow>
        {/* Toast for showing messages */}
        <IonToast
          isOpen={toastState.isOpen}
          message={toastState.message}
          duration={2000}
          color={toastState.color}
          position='top'
          onDidDismiss={() => setToast({ isOpen: false, message: '', color: '' })}
        />
      </IonGrid>
    </AccessControl>
  )
}
