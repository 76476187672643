import { useState } from 'react'
import Axios from 'axios'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useHistory } from 'react-router-dom'
import { colors } from './theme/colors'
import { IonButton, IonContent, IonGrid, IonInput, IonLabel, IonPage, IonRouterLink, IonRow, IonToast } from '@ionic/react'
import StandardCenterCenter from './commonComponents/StandardCenterCenter'
import commonStyles from './theme/commonstyles.module.css'

const classes = {
  paper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
    backgroundColor: colors.primaryBlue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 10,
  },
  submit: {
    margin: 10,
  },
}

const SignUp = () => {
  const hist = useHistory()
  const [disabled, setDisabled] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signupCode, setSignupCode] = useState('') // New state for the Early Bird Signup code
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' }) // Toast state

  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  const isFormValid = () => {
    return (
      isEmailValid(email) && password.trim().length >= 6 && signupCode.trim() === 'Scixxor4Life' // Validate the Early Bird Signup code
    )
  }

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const handleSignUp = async () => {
    setDisabled(true)
    try {
      let response = await createUserWithEmailAndPassword(getAuth(), email, password)
      await Axios.post('/user', { email: email, firebaseId: response.user.uid })
      showToastMessage('Registration successful! Redirecting...', 'success')
      setTimeout(() => {
        hist.push('/core/dashboard')
      }, 2000) // Delay redirect to show toast message
    } catch (error) {
      showToastMessage('Error: ' + error.message, 'danger')
      setDisabled(false)
    }
  }

  return (
    <IonPage>
      <IonContent className='regularBackground'>
        <StandardCenterCenter>
          <IonGrid style={{ padding: '1rem' }}>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3rem',
                marginBottom: '0.5rem',
              }}
            >
              <IonLabel style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>SCIXXOR</IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <IonLabel style={{ fontSize: '0.9rem' }}>The easiest way to manage and grow your salon or barbershop</IonLabel>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: 30 }}>Register</h1>
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize: 14 }}>Email</IonLabel>
              <IonInput
                style={{ width: '100%' }}
                className={commonStyles.inputStyle}
                name='Email'
                placeholder='john@example.com'
                value={email}
                onIonInput={(e) => setEmail(e.detail.value)}
              />
            </IonRow>
            <IonRow style={{ display: 'flex', flexDirection: 'column' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize: 14, marginTop: 24 }}>Password</IonLabel>
              <IonInput
                style={{ width: '100%' }}
                className={commonStyles.inputStyle}
                name='Password'
                placeholder='password'
                type='password'
                value={password}
                onIonInput={(e) => setPassword(e.detail.value)}
              />
            </IonRow>
            {/* New Early Bird Signup Code Field */}
            <IonRow style={{ display: 'flex', flexDirection: 'column', marginTop: '1.5rem' }}>
              <IonLabel style={{ margin: '10px 0', fontWeight: 'bold', fontSize: 14 }}>Early Bird Signup Code</IonLabel>
              <IonInput
                style={{ width: '100%' }}
                className={commonStyles.inputStyle}
                name='SignupCode'
                placeholder='Enter your code'
                value={signupCode}
                onIonInput={(e) => setSignupCode(e.detail.value)}
              />
            </IonRow>
            <IonButton
              onClick={handleSignUp}
              disabled={!isFormValid() || disabled} // Disable the button if the form is not valid
              style={{ margin: '1.5rem 0' }}
              color={'dark'}
              expand='block'
            >
              Register
            </IonButton>
            <IonRow
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonRouterLink routerLink='/login'>
                <h6 style={{ color: 'black', fontWeight: 'bold' }}>Login</h6>
              </IonRouterLink>
              <h6 style={{ fontWeight: 'bold' }}>Forgot Password</h6>
            </IonRow>
          </IonGrid>
        </StandardCenterCenter>
        <IonToast
          isOpen={toast.isOpen}
          message={toast.message}
          color={toast.color}
          onDidDismiss={() => setToast({ ...toast, isOpen: false })}
          duration={2000}
          position='top'
        />
      </IonContent>
    </IonPage>
  )
}

export default SignUp
