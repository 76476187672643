import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSpinner, IonToast } from '@ionic/react'
import {
  calendarOutline,
  cashOutline,
  chatboxEllipsesOutline,
  documentOutline,
  imageOutline,
  list,
  notificationsOutline,
  settingsOutline,
  walkOutline,
  walletOutline,
} from 'ionicons/icons'
import { useCallback, useContext, useState } from 'react'
import CustomerNotesModal from '../commonComponents/customerNotes'
import SchedulerModal from '../Scheduler/modal'
import { colors } from '../theme/colors'
import { AuthContext } from '../auth'
import GetLocationDataBasedOnLocationId from '../Utils/GetLocationDataBasedOnLocationId'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import './CustomerDetailListOptions.css'
import NoFutureApptsCard from '../commonComponents/NoFutureApptsCard'
import { useHistory } from 'react-router'
import axios from 'axios'
import AdvancedSettingsModal from './AdvancedSettings'
import { AccessControl } from '../AccessControl'
export default function CustomerDetailsListOptions({ apptCount, userEntitlements, customerTemp, customer_id, balance, creditLoading, conversationDetails }) {
  const history = useHistory()
  const { locationData, locationId, businessData, userData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const [loadingConversation, setLoadingConversation] = useState(false)
  const maxLocationAppts = selectedLocationData?.appointmentSettings?.futureAppointmentsLimit
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)
  const [openScheduler, setOpenScheduler] = useState({ isOpen: false, isWalkin: false })
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false)
  const [notesId, setNotesId] = useState(null)
  const [notes, setNotes] = useState('')
  const [lastEditTime, setLastEditTime] = useState(null)
  const [lastEditorName, setLastEditorName] = useState(null)
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const openApptAlertModal = () => {
    setAlertModalOpen(true)
  }
  const closeApptAlertModal = () => {
    setAlertModalOpen(false)
  }
  const fetchNotes = useCallback(async () => {
    try {
      const response = await axios.get(`/customer_v2/getNotes`, {
        params: {
          customerId: customer_id,
          businessId: businessData._id,
        },
      })

      setNotesId(response.data._id)
      setNotes(response.data?.notes)
      setLastEditTime(response.data?.lastEditTime)
      setLastEditorName(response.data?.lastEditorName)
    } catch (error) {
      console.error('Error fetching notes:', error)
    } finally {
      // Optionally handle any final state here
    }
  }, [customer_id, businessData._id])
  const handleMessageCustomer = async () => {
    if (conversationDetails?.twilioConversationId) {
      history.push(`/core/messages/${conversationDetails?.twilioConversationId}`)
    } else {
      setLoadingConversation(true)
      try {
        const threadInput = {
          locationId: locationId,
          businessId: businessData?._id,
          userId: userData?._id,
          customerId: customer_id,
        }
        const threadResponse = await axios.post('/conversation', threadInput)

        if (threadResponse?.data?.message === 'success') {
          setLoadingConversation(false)
          showToastMessage('Conversation Started', 'success')
          history.push(`/core/messages/${threadResponse?.data?.twilioConversationId}`)
        }
      } catch (error) {
        setLoadingConversation(false)
        showToastMessage('Failed to create conversation. Please try again.', 'danger')
      }
    }
  }

  const creditBalanceStyles =
    balance >= 0
      ? null
      : {
          color: colors.primaryBlue,
        }
  return (
    <>
      <IonList inset={true} mode='ios'>
        {/* <IonItem detail={true} onClick={handleMessageCustomer}>
          <IonIcon icon={chatboxEllipsesOutline} slot='start'></IonIcon>
          <IonLabel>Message</IonLabel>
          {loadingConversation && <IonSpinner name='lines' />}
        </IonItem> */}
        <IonItem detail={true} routerLink={`/core/history/${customer_id}`}>
          <IonIcon icon={list} slot='start'></IonIcon>
          <IonLabel>Appointment History</IonLabel>
        </IonItem>
        <IonItem detail={true} routerLink={`/core/paymentHistory/${customer_id}`}>
          <IonIcon icon={cashOutline} slot='start'></IonIcon>
          <IonLabel>Payment History</IonLabel>
        </IonItem>
        <AccessControl componentName='custoemr_credit_balance'>
          <IonItem detail={true} routerLink={`/core/creditBalance/${customer_id}`}>
            <IonIcon icon={walletOutline} slot='start'></IonIcon>
            <IonLabel>Credit Balance</IonLabel>
            {!creditLoading && balance < 0 && (
              <>
                {balance !== 0 && (
                  <IonLabel style={creditBalanceStyles} slot='end'>
                    {/* {balance > 0 ? '' : '-'}${balance / 100?.toString().replace('-', '')} */}
                    {formatDollarValue(balance / 100)}
                  </IonLabel>
                )}
              </>
            )}
          </IonItem>
        </AccessControl>

        <IonItem detail={true} routerLink={`/core/manageNotifications/${customer_id}`}>
          <IonIcon icon={notificationsOutline} slot='start'></IonIcon>
          <IonLabel>Manage Notifications</IonLabel>
        </IonItem>

        <IonItem
          detail={true}
          onClick={() => {
            setNotesOpen(true)
          }}
        >
          <IonIcon icon={documentOutline} slot='start'></IonIcon>
          <IonLabel>Customer Notes</IonLabel>
        </IonItem>
        <IonItem detail={true} routerLink={`/core/gallery/${customer_id}`}>
          <IonIcon icon={imageOutline} slot='start'></IonIcon>
          <IonLabel>Customer Pictures</IonLabel>
        </IonItem>
        <IonItem
          detail={true}
          onClick={() => {
            showToastMessage(
              ` Sorry, you cannot book more than ${maxLocationAppts} appointment ${
                maxLocationAppts > 1 ? 's' : ''
              } in the future.You already have ${apptCount} appointment
        ${apptCount !== 1 ? 's' : ''} in the future.If you have any questions or need assistance, please contact our support team.`,
              'danger'
            )
            setOpenScheduler({ isOpen: true, isWalkin: false })
          }}
        >
          <IonIcon icon={calendarOutline} slot='start'></IonIcon>
          <IonLabel>Book Appointment</IonLabel>
        </IonItem>
        <IonItem
          detail={true}
          onClick={() => {
            setOpenScheduler({ isOpen: true, isWalkin: true })
          }}
        >
          <IonIcon icon={walkOutline} slot='start'></IonIcon>
          <IonLabel>Book Walk-in</IonLabel>
        </IonItem>
        <AccessControl componentName='customer_advanced_settings'>
          <IonItem
            detail={true}
            onClick={() => {
              setAdvancedSettingsOpen(true)
            }}
          >
            <IonIcon icon={settingsOutline} slot='start'></IonIcon>
            <IonLabel>Advanced</IonLabel>
          </IonItem>
        </AccessControl>
      </IonList>
      {/* <IonModal isOpen={alertModalOpen} onDidDismiss={closeApptAlertModal}>
        <CommonHeader closeButton={true} closeModal={closeApptAlertModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <NoFutureApptsCard apptCount={apptCount} />
          </StandardContainer>
        </IonContent>
      </IonModal> */}
      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        duration={2000}
        position='top'
      />
      <CustomerNotesModal
        notesId={notesId}
        fetchNotes={fetchNotes}
        isOpen={notesOpen}
        setNotes={setNotes}
        notes={notes}
        setOpen={setNotesOpen}
        customerId={customer_id}
        lastEditTime={lastEditTime}
        lastEditorName={lastEditorName}
      />
      <AdvancedSettingsModal isOpen={advancedSettingsOpen} setOpen={setAdvancedSettingsOpen} customer={customerTemp} />
      <SchedulerModal
        isOpen={openScheduler?.isOpen || false}
        isWalkin={openScheduler?.isWalkin || false}
        setIsOpen={setOpenScheduler}
        onSuccess={() => {}}
        customerId={customer_id}
        customer={customerTemp}
      />
    </>
  )
}

function formatDollarValue(value) {
  if (value < 0) {
    const formattedValue = Math.abs(value).toFixed(2)
    return `($${formattedValue})`
  } else {
    return `$${value.toFixed(2)}`
  }
}
