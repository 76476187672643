import { IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react'
import { addCircleOutline, locationSharp } from 'ionicons/icons'
import { useHistory, useLocation } from 'react-router'
import { AccessControl } from '../../AccessControl'
export default function AddLocationAndBusinessButtons() {
  const history = useHistory()
  const windowLocation = useLocation()

  // Inside your component
  const handleLinkClick = () => {
    history.push('/core/locations', { openCreatePopUp: true }) // Navigate to the specified route
  }
  const handleCreateBusiness = () => {
    history.push('/setup', { newBusiness: true }) // Navigate to the specified route
  }

  const isAddLocationSelected = windowLocation.pathname === `/core/locations`
  const isBusinessSelected = windowLocation.pathname === `/setup`

  return (
    <>
      <IonGrid>
        {/**TODO with Scixxor */}
        <AccessControl componentName={"create_location_button"}>
          <IonRow
            onClick={() => handleLinkClick()}
            style={{
              backgroundColor: isAddLocationSelected ? '#cacaca' : '',
              display: 'inline-flex',
              alignItems: 'center',
              marginTop: '1rem',
              cursor: 'pointer',
            }}
          >
            <IonIcon style={{ marginLeft: 0, marginRight: 16 }} icon={locationSharp}></IonIcon>
            <IonLabel>Add Location</IonLabel>
          </IonRow>
        </AccessControl>
        <AccessControl componentName={"create_business_button"}>
          <IonRow
            onClick={handleCreateBusiness}
            style={{
              backgroundColor: isBusinessSelected ? '#cacaca' : '',
              display: 'inline-flex',
              alignItems: 'center',
              marginTop: '1rem',
              cursor: 'pointer',
            }}
          >
            <IonIcon style={{ marginLeft: 0, marginRight: 10 }} icon={addCircleOutline}></IonIcon>&nbsp; <IonLabel>Create New Business</IonLabel>
          </IonRow>
        </AccessControl>
      </IonGrid>
    </>
  )
}
