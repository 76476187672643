import { IonContent, IonPage } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import axios from 'axios'
import { AuthContext } from '../auth'
import { convertMonthNumberToText } from '../utils'
import StatItems from './StatItems'

export default function StatsDetails() {
  const { businessData, locationId } = useContext(AuthContext)
  const [stats, setStats] = useState()
  const [subscriberAverage, setSubscriberAverage] = useState()
  const [title, setTitle] = useState('')
  let queryParams = new URLSearchParams(window.location.search)
  let month = queryParams.get('month')
  let year = queryParams.get('year')
  async function getStats(month, year) {
    try {
      let r = await axios.get(
        `/location_v2/stats?businessId=${businessData?._id}&locationId=${locationId}&month=${month}&year=${year}`,
      )
      setStats(r.data)
    } catch (error) {}
  }
  useEffect(() => {
    let totalAppointments = 0
    let totalSubscribers = 0

    for (let s of stats?.subscriberAppointments || []) {
      totalAppointments += s.totalAppointments
      totalSubscribers += s.countOfSubscribers * (s.numberOfPeopleInPackage || 1)
    }
    setSubscriberAverage(totalAppointments / totalSubscribers)
  }, [stats?.subscriberAppointments])
  useEffect(() => {
    getStats(month, year)
    const title = convertMonthNumberToText(month)
    setTitle(title)
  }, [queryParams])

  return (
    <IonPage id='main-content'>
      <CommonHeader title={title ? title + ' ' + year : ''} backIcon={true}></CommonHeader>
      <IonContent>
        <StandardContainer>
          <StatItems
            month={month}
            year={year}
            stats={stats}
            subscriberAverage={subscriberAverage}
          />
        </StandardContainer>
      </IonContent>
    </IonPage>
  )
}
