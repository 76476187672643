import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { IonButton, IonCard, IonContent, IonPage, IonSpinner, IonToast } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import DetailsHeader from '../commonComponents/DetailsHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import CardOnFile from '../Payment/cardOnFile'
import CustomerSubscription from './subscription'
import checkEmailExists from '../Utils/CheckEmailValidation'
import CheckIsManagerOrAdmin from '../Utils/CheckIsManagerOrAdmin'
import CustomerDetailsListOptions from './CustomerDetailsListOptions'

export default function Details({ match }) {
  let customer_id = match?.params?.id
  const [customer, setCustomer] = useState()
  const [customerTemp, setCustomerTemp] = useState()
  const [locations, setLocations] = useState()
  const [loading, setLoading] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(null)
  const [reloadTrigger, setReloadTrigger] = useState(null)
  const [emailValidationMessage, setEmailValidationMessage] = useState(false)
  const { businessData, locationId, userData } = useContext(AuthContext)
  const windowLocation = useLocation()
  const [creditLoading, setCreditLoading] = useState(true)
  const [creditData, setCreditData] = useState(false)
  const [apptCount, setApptCount] = useState(0)
  const [conversationDetails, setConversationDetails] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }
  const getCustomer = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/customer_v2?id=${customer_id}`)
      setCustomer(response.data)
      setCustomerTemp(response.data)
      getCustomerCredit(response?.data?.stripeId)
    } catch (error) {
      console.error('Failed to load customer data:', error) // Log the error for debugging
      // You can set an error state here if needed, e.g., setError('Failed to load customer data');
    } finally {
      setLoading(false)
    }
  }

  const getCustomerAppointmentCount = async () => {
    try {
      const response = await axios.get(`appointment_v2/count?customerId=${customer_id}`)
      setApptCount(response?.data?.count || 0)
    } catch (error) {
      console.error('Failed to load customer appointment count:', error) // Log the error for debugging
      // You can set an error state here if needed
    }
  }

  const getCustomerCredit = async (stripeId) => {
    setCreditLoading(true) // Ensure loading state is set before the request
    try {
      const creditResponse = await axios.get(`/customer_v2/credit/balance?customerStripeId=${stripeId}`)
      if (creditResponse && creditResponse.status === 200) {
        const creditData = creditResponse.data
        setCreditData(creditData)
      } else {
        console.error('Failed to fetch customer credit data:', creditResponse) // Log unexpected response
      }
    } catch (error) {
      console.error('Error fetching customer credit balance:', error) // Log the error for debugging
    } finally {
      setCreditLoading(false) // Ensure loading state is reset
    }
  }

  const checkConversationExists = async () => {
    try {
      const response = await axios.get(
        `/conversation/find?locationId=${locationId}&businessId=${businessData?._id}&userId=${userData?._id}&customerId=${customer_id}`
      )
      if (response) {
        setConversationDetails(response?.data)
      } else {
        console.error('No conversation found') // Log if no conversation exists
      }
    } catch (error) {
      console.error('Error checking if conversation exists:', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    setCreditLoading(true)
    getCustomer()
    getCustomerAppointmentCount()
    checkConversationExists()
  }, [customer_id])

  useEffect(() => {
    if (windowLocation.pathname == `/core/customers/${customer_id}`) {
      getCustomer()
      checkConversationExists()
      getCustomerAppointmentCount()
      setReloadTrigger(Math.floor(Math.random() * (100000 - 1 + 1) + 1))
    }
  }, [businessData, windowLocation])

  const getLocationsList = async () => {
    setLoading(true) // Optionally set loading state if applicable
    try {
      const response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)

      if (response.status === 200) {
        setLocations(response.data)
      } else {
        console.error('Failed to fetch locations:', response) // Log if response is not successful
      }
    } catch (error) {
      console.error('Error fetching locations list:', error) // Log the error for debugging
    } finally {
      setLoading(false) // Ensure loading state is reset
    }
  }

  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const updateCustomer = async (field, value) => {
    setCustomerTemp(value)

    /* if the changed field is email then 
    we have to check if the user email already existsfor any user 
    */
    if (field == 'email' && customer.email != value.email) {
      if (field == 'email' && value.email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const isValid = emailPattern.test(value.email)
        if (isValid) {
          setEmailValidationMessage(false)
          try {
            const emailExists = await checkEmailExists('customer', value.email)

            //if emailValidationMessage then seterror message as email already exists
            const validationMessage = emailExists.emailExists ? 'Email Already In Use' : false
            setEmailValidationMessage(validationMessage)
          } catch (error) {}
        } else {
          setEmailValidationMessage('Please Enter A Valid Email')
        }
      } else if (field == 'email') {
        setEmailValidationMessage('Email Address is required')
      }
    } else {
      setEmailValidationMessage('')
    }
  }

  const save = async () => {
    try {
      await axios.patch(`/customer_v2?id=${customer_id}`, customerTemp)
      showToastMessage('Customer details updated successfully.', 'success')
    } catch (error) {
      showToastMessage('Failed to update customer details. Please try again.', 'danger')
    }
    await getCustomer()
    checkConversationExists()
    getCustomerAppointmentCount()
  }

  const cancel = () => {
    setCustomerTemp(customer)
  }

  const generateStripeId = async () => {
    try {
      await axios.post(`/customer_v2/generateStripeId`, { customerId: customer._id })
      getCustomer()
      checkConversationExists()

      getCustomerAppointmentCount()
    } catch (error) {
      showToastMessage('Error Generating Stripe ID', 'danger')

      alert('Error Generating stripe Id')
    }
  }

  const deleteCustomer = async () => {
    if (window.confirm('Are you sure you want to delete the customer?')) {
      // Delete it!
      // await axios.delete(`/customer?id=${customer_id}`)
      // history.push(-1)
    }
  }
  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  return (
    <IonPage>
      <DetailsHeader save={save} cancel={cancel} valueChanged={false} title={customerTemp ? customerTemp?.firstName + ' ' + customerTemp?.lastName : ''} />
      <IonContent>
        <StandardContainer>
          {customer ? (
            <>
              <GeneralDetails
                save={save}
                emailValidationMessage={emailValidationMessage}
                customer={customerTemp}
                updateCustomer={updateCustomer}
                locations={locations}
                locationId={locationId}
              />
              <div style={{ borderRadius: 10, border: '1px solid #e0e0e0', padding: 0, margin: 16 }}>
                <CustomerSubscription customer={customerTemp} setIsSubscribed={setIsSubscribed} reloadTrigger={reloadTrigger} />
              </div>
              <h6 style={{ marginLeft: 16, marginTop: 24, marginBottom: 16 }}>Actions</h6>
              <CustomerDetailsListOptions
                conversationDetails={conversationDetails}
                apptCount={apptCount}
                creditLoading={creditLoading}
                balance={creditData?.ending_balance}
                userEntitlements={userEntitlements}
                customer_id={customer_id}
                customerTemp={customerTemp}
              />
              <h6 style={{ marginLeft: 16, marginTop: 24, marginBottom: 16 }}>Card on File</h6>
              {!loading && (
                <>
                    <IonCard style={{ padding: 10 }} className='flat'>
                      <CardOnFile customerId={customer._id} watcherValue={isSubscribed} />
                    </IonCard>

                </>
              )}
            </>
          ) : (
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: 200,
              }}
            >
              <IonSpinner name='dots' />
            </div>
          )}
        </StandardContainer>
        <IonToast
          position='top'
          isOpen={toast.isOpen}
          message={toast.message}
          color={toast.color}
          onDidDismiss={() => setToast((prev) => ({ ...prev, isOpen: false }))}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  )
}
