import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../auth'
import { roles } from '../StaticData/StaticData'
export default function EachLocationPermission({ location_id, user }) {
  const { locationData, locationId, businessData } = useContext(AuthContext)
  const [updatingPermissions, setUpdatingPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [selectedPermissions, setSelectedPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [locationDetails, setLocationData] = useState(false)
  const [loading, setLoading] = useState(false)

  // Toast state
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }

  const handleupdatingPermissions = (value, permission) => {
    const updatedPermissions = { ...updatingPermissions }

    Object.keys(updatedPermissions).forEach((key) => {
      updatedPermissions[key] = key === permission ? value : false
    })

    setUpdatingPermissions(updatedPermissions)
  }

  const getLocationDetails = async () => {
    if (location_id) {
      setLoading(true)
      try {
        let response = await axios.get(`/location_v2?id=${location_id}`)
        if (response && response.status === 200) {
          setLocationData(response.data)
        }
      } catch (error) {
        console.error('Failed to fetch location details.', error) // Log the error for debugging
      } finally {
        setLoading(false)
      }
    }
  }

  const updateEntitlements = () => {
    const getLocationEntitlements = () => {
      const location = user?.businesses.flatMap((business) => business.locations).find((loc) => loc._id === location_id)
      return location ? location.entitlements : null
    }

    const updatedPermissions = getLocationEntitlements()
    if (updatedPermissions) {
      setSelectedPermissions(updatedPermissions)
    } else {
      console.error('Location not found in the business array')
    }
  }

  useEffect(() => {
    updateEntitlements()
    getLocationDetails()
  }, [])

  useEffect(() => {
    getLocationDetails()
  }, [location_id])

  const handlePermission = async (permission) => {
    const updatedPermissions = JSON.parse(JSON.stringify({ ...selectedPermissions }))

    Object.keys(updatedPermissions).forEach((key) => {
      updatedPermissions[key] = key === permission
    })

    setSelectedPermissions(updatedPermissions)
    handleupdatingPermissions(true, permission)

    let userBusiness = JSON.parse(JSON.stringify([...user.businesses]))
    const businessIndex = userBusiness.findIndex((business) => business._id === businessData?._id)

    if (businessIndex !== -1) {
      const locationIndex = userBusiness[businessIndex].locations.findIndex((location) => location._id === location_id)

      if (locationIndex !== -1) {
        const locationEntitlements = userBusiness[businessIndex].locations[locationIndex].entitlements

        Object.keys(locationEntitlements).forEach((key) => {
          locationEntitlements[key] = key === permission ? updatedPermissions[permission] : false
        })

        const data = {
          _id: user?._id,
          email: user?.email,
          businesses: userBusiness,
        }

        try {
          let response = await axios.patch(`/user`, data)
          if (response && response.status === 200) {
            showToastMessage(`${permission} permission updated successfully.`, 'success')
            handleupdatingPermissions(false, permission)
          }
        } catch (error) {
          console.error(error)
          showToastMessage('Failed to update permissions.', 'danger')
          handleupdatingPermissions(false, permission)
        }
      }
    }
  }

  return (
    <IonCard style={{ padding: '10px' }}>
      {loading && <IonSpinner name='dots' />}
      {!loading && (
        <IonGrid>
          <IonRow style={{ display: 'flex', alignItems: 'end' }}>
            <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size='large' />
            <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>{locationDetails.name} Permissions</h6>
          </IonRow>
          <IonRow style={{ marginTop: '1rem' }}>
            {roles.map((item, index) => {
              return (
                <IonRow key={index}>
                  <IonCol size='auto'>
                    <h6
                      style={{
                        margin: '5px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {item.role}
                    </h6>
                    <IonRow onClick={() => handlePermission(item.value)}>
                      <IonCol size='10'>
                        <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                      </IonCol>
                      <IonCol>
                        {updatingPermissions[item.value] ? (
                          <IonSpinner name='lines' />
                        ) : (
                          <IonCheckbox style={{ height: '1.5rem', width: '1.5rem' }} checked={selectedPermissions[item.value]} />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              )
            })}
          </IonRow>
        </IonGrid>
      )}
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonCard>
  )
}
