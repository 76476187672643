import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { IonItem, IonLabel, IonList } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'
import { convertMonthNumberToText } from '../utils'
import { AuthContext } from '../auth'
import StatItems from './StatItems'

export default function LocationStats() {
  const { businessData, locationId } = useContext(AuthContext)
  if (!businessData?._id) return 'location null...'

  let { month, year } = getCurrentMonthAndYear()
  return (
    <StandardContainer>
      <h4 style={{ padding: 16, paddingBottom: 0 }}>{getCurrentMonth()} Stats</h4>
      <StatsBlock businessId={businessData?._id} month={month} year={year} />
      <Historical businessId={businessData?._id} />
    </StandardContainer>
  )
}

function getCurrentMonth() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  return months[currentMonth]
}

function getCurrentMonthAndYear() {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // Months are zero-based, so we add 1
  const currentYear = currentDate.getFullYear()

  return { month: currentMonth, year: currentYear }
}

function StatsBlock({ businessId, month, year }) {
  const [stats, setStats] = useState()
  const { businessData, locationId } = useContext(AuthContext)

  const [subscriberAverage, setSubscriberAverage] = useState()

  async function getStats() {
    try {
      let r = await axios.get(
        `/location_v2/stats?businessId=${businessData?._id}&month=${month}&year=${year}`,
      )
      setStats(r.data)
    } catch (error) {}
  }
  useEffect(() => {
    getStats()
  }, [businessData, locationId])

  useEffect(() => {
    const interval = setInterval(() => {
      getStats()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let totalAppointments = 0
    let totalSubscribers = 0

    for (let s of stats?.subscriberAppointments || []) {
      totalAppointments += s.totalAppointments
      totalSubscribers += s.countOfSubscribers * (s.numberOfPeopleInPackage || 1)
    }
    setSubscriberAverage(totalAppointments / totalSubscribers)
  }, [stats?.subscriberAppointments])

  return (
    <>
      <StatItems month={month} year={year} stats={stats} subscriberAverage={subscriberAverage} />
    </>
  )
}

function Historical({ businessId }) {
  const hist = generateMonthYearArray(1680347469000, true)

  return (
    <IonList mode='ios' inset={true}>
      {hist?.map(i => (
        <>
          <IonItem routerLink={`/core/stats?month=${i.month}&year=${i.year}`} detail={true}>
            <IonLabel>
              <strong>
                {convertMonthNumberToText(i.month)} {i.year}
              </strong>
            </IonLabel>
          </IonItem>
        </>
      ))}
    </IonList>
  )
}

function generateMonthYearArray(startDate, excludeCurrentMonth = false) {
  const currentDate = new Date()
  const start = new Date(startDate)
  const monthYearArray = []

  while (currentDate > start) {
    const month = start.getMonth() + 1 // Months are zero-based, so we add 1
    const year = start.getFullYear()

    if (
      !(
        excludeCurrentMonth &&
        currentDate.getMonth() === start.getMonth() &&
        currentDate.getFullYear() === start.getFullYear()
      )
    ) {
      monthYearArray.push({ month, year })
    }

    // Move to the previous month
    start.setMonth(start.getMonth() + 1)
  }

  return monthYearArray.reverse()
}
