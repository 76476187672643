import { useEffect, useState } from 'react'
import axios from 'axios'
import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonModal, IonRow, IonSpinner, IonText } from '@ionic/react'
import { getFormattedDateWithAmPm } from '../../utils'
import { useHistory } from 'react-router'
import PaymentDetails from './PaymentDetails'

export default function PaymentHistory({ customer_id }) {
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [paymentshistory, setPaymentsHistory] = useState([])
  const [detailPopupOpen, setDetailPopupOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState(false)
  const getCustomerDetails = async () => {
    try {
      let response = await axios.get(`/customer_v2?id=${customer_id}`)
      if (response?.data?.stripeId) {
        getCustomerPaymentHistory(response.data.stripeId)
      }
    } catch (error) {
      console.error('Error fetching customer details:', error) // Log the error for debugging
    }
  }

  const getCustomerPaymentHistory = async (id) => {
    setLoading(true)

    try {
      const paymentsResponse = await axios.get(`customer_v2/transactions?stripeId=${id}`)

      setPaymentsHistory(paymentsResponse.data)
    } catch (error) {
      console.error('Error fetching payment history:', error) // Log the error for debugging
      setPaymentsHistory([]) // Reset the payment history in case of an error
    } finally {
      setLoading(false) // Ensure loading state is set to false regardless of success or error
    }
  }

  const openPaymentDetailModal = (payment) => {
    setSelectedPayment(payment)
    setDetailPopupOpen(true)
  }
  const closePaymentDetailModal = (payment) => {
    setSelectedPayment(false)
    setDetailPopupOpen(false)
  }
  useEffect(() => {
    getCustomerDetails()
    //get all the paymentshistory for the selected customer
  }, [customer_id])
  useEffect(() => {
    getCustomerDetails()
    //get all the paymentshistory for the selected customer
  }, [])

  const getStatusLabel = (status) => {
    switch (status) {
      case 'succeeded':
        return 'Success'
      case 'pending':
        return 'Pending'
      case 'failed':
        return 'Failed'
      case 'requires_payment_method':
        return 'Req Payment Method'
      case 'canceled':
        return 'Cancelled'
      // Add more cases as needed
      default:
        return status
    }
  }
  return (
    <>
      {/* <IonCard style={{ padding: '1rem' }}> */}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IonSpinner name='dots' />
        </div>
      )}
      {!loading && (
        <IonList mode='ios' style={{ borderRadius: '1rem' }} inset={true}>
          {paymentshistory && paymentshistory.length > 0 ? (
            paymentshistory.map((payment) => {
              const date = new Date(payment.created * 1000) // Convert seconds to milliseconds
              const formattedDate = getFormattedDateWithAmPm(date)
              const formattedAmount = `$${(payment.amount / 100).toFixed(2)}`

              return (
                <IonItem style={{ cursor: 'pointer' }} onClick={() => openPaymentDetailModal(payment)}>
                  <IonGrid>
                    <IonRow>
                      <IonCol size='6' style={{ padding: '0.3rem 0' }}>
                        <h6 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          <strong>{formattedAmount}</strong>
                        </h6>
                        <h6 style={{ color: 'grey' }}>
                          {formattedDate} | {payment.description}
                        </h6>
                      </IonCol>
                      <IonCol size='6' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {payment.status == 'succeeded' ? (
                          <div
                            style={{
                              backgroundColor: '#bdffbd',
                              border: '2px solid #007a00',
                              borderRadius: '5px',
                              color: '#007a00',
                              padding: '5px',
                              width: '100%',
                            }}
                          >
                            <h6 style={{ textAlign: 'center', margin: '0' }}>{getStatusLabel(payment.status)}</h6>
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: '#ffcaca',
                              border: '2px solid #ca0000',
                              borderRadius: '5px',
                              color: '#ca0000',
                              padding: '5px',
                              width: '100%',
                            }}
                          >
                            <h6 style={{ textAlign: 'center', margin: '0' }}>{getStatusLabel(payment.status)}</h6>
                          </div>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              )
            })
          ) : (
            <div style={{ padding: '1rem' }}>
              <IonLabel>No Data Found</IonLabel>
            </div>
          )}
        </IonList>
      )}

      <IonModal onDidDismiss={closePaymentDetailModal} isOpen={detailPopupOpen}>
        {selectedPayment && <PaymentDetails customer_id={customer_id} selectedPayment={selectedPayment} closePaymentDetailModal={closePaymentDetailModal} />}
      </IonModal>
      {/* </IonCard> */}
    </>
  )
}
