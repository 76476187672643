import { useState, useContext, useEffect } from 'react'
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonTextarea, IonSpinner, IonToggle, IonButtons } from '@ionic/react'
import axios from 'axios'
import 'react-quill/dist/quill.snow.css' // import the styles
import { AuthContext } from '../auth'
import StandardContainer from '../commonComponents/StandardContainer'


function AdvancedSettingsModal({ isOpen, setOpen, customer, fetchCustomer }) {
  const { businessData, selectedLocation } = useContext(AuthContext)
  const [isSaving, setSaving] = useState(false)
  const [isBlocked, setIsBlocked] = useState(customer?.isBlocked)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const handleBlockToggle = async () => {
    setIsUpdating(true)
    setTimeout(() => setShowSpinner(true), 500)
    try {
      await axios.post('/customer_v2/block', {
        customerId: customer._id,
        isBlocked: !isBlocked
      })
      setIsBlocked(!isBlocked)
      fetchCustomer()
    } catch (error) {
      console.error('Error updating customer block status:', error)
    } finally {
      setIsUpdating(false)
      setShowSpinner(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <IonModal isOpen={isOpen} trigger='open-modal'>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>Close</IonButton>
          </IonButtons>
          <IonTitle style={{ color: 'black' }}>Advanced Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer medium={true}>
          <IonItem>
            <IonLabel>Block Customer</IonLabel>
            {isUpdating && showSpinner ? (
              <IonSpinner name="dots" slot="end" />
            ) : (
              <IonToggle checked={isBlocked} onIonChange={handleBlockToggle} disabled={isUpdating} slot="end" />
            )}
          </IonItem>
          <p style={{margin:16, color:'grey'}}>This feature will block a customer from being able to book appointments.</p>
        </StandardContainer>
      </IonContent>
    </IonModal>
  )
}

export default AdvancedSettingsModal
