import moment from 'moment-timezone'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import axios from 'axios'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonProgressBar,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { add, cafeOutline, calendarOutline, chevronBack, chevronForward, footstepsOutline } from 'ionicons/icons'
import { formatDateToISOString, formatDateWithWeekDay } from '../utils'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import StepController from '../Scheduler/stepController'
import AddBreak from './CalendarView5/AddBreak'
import { fontSizes, fontWeights } from '../theme/typography'

const ListViewHeader = ({ refreshAppointments, displayWeeks, displayCalendar, getBreaks, employees, selectedDate, setSelectedDate, loading, handleView }) => {
  const formatDateToISO = (date) => {
    const pad = (n) => (n < 10 ? '0' + n : n)
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
  const [monthModalOpen, setMonthModalOpen] = useState(false) // State to control modal visibility

  const [startDate, setStartDate] = useState(moment(selectedDate))
  const [view, setView] = useState('week') // State to toggle between week and month view
  const [date, setDate] = useState(formatDateToISO(new Date(selectedDate)))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBreakModalOpen, setIsBreakModalOpen] = useState(false)
  const { businessData, locationId } = useContext(AuthContext)
  const startOfWeek = moment(selectedDate).startOf('week') // Start of the current selected week
  const endOfPrevWeek = moment(startOfWeek).subtract(1, 'day').endOf('day') // End of previous week
  const startOfNextWeek = moment(startOfWeek).add(1, 'week') // Start of next week
  const [apptModalOpen, setApptModalOpen] = useState(false)
  const [isWalkin, setIsWalkin] = useState(false)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  // Array of days for previous week's last day, current week, and next week's first day
  const weekDays = [
    moment(endOfPrevWeek), // Previous week's last day
    ...Array.from({ length: 7 }, (_, i) => moment(startOfWeek).add(i, 'days')), // Current week
    moment(startOfNextWeek), // Next week's first day
  ]
  const handlePrevWeek = () => {
    const newSelectedDate = new Date(selectedDate)
    newSelectedDate.setDate(newSelectedDate.getDate() - 7)
    setSelectedDate(newSelectedDate)
    setDate(formatDateToISOString(new Date(newSelectedDate)))
  }

  const handleNextWeek = (date) => {
    const newSelectedDate = new Date(date)
    newSelectedDate.setDate(newSelectedDate.getDate() + 7)
    setSelectedDate(newSelectedDate)
    setDate(formatDateToISOString(new Date(newSelectedDate)))
  }

  const handleClick = (day) => {
    if (!loading) {
      setSelectedDate(day.toDate())
      setDate(formatDateToISOString(new Date(day)))
    }
  }
  const handleSaveBreak = async (data) => {
    const businessId = businessData?._id
    const breakInput = {
      ...{ locationId, businessId },
      ...data,
      repeat: data.repeat,
    }

    try {
      await axios.post(`/employee/break`, breakInput)
      getBreaks()
      closeModal()
      closeBreakModal()
      // Show success toast message
      setToast({ isOpen: true, message: 'Break saved successfully!', color: 'success' })
    } catch (error) {
      setToast({ isOpen: true, message: 'Sorry, there was an issue saving. Try again later.', color: 'danger' })
    }
  }

  const handleMonthDateChange = (date) => {
    console.log(date, 'datedate')
    setSelectedDate(new Date(date))
    setDate(date)
    setStartDate(moment(date))
    setView('week')
  }

  const handleCalendarIconClick = () => {
    setView(view === 'week' ? 'month' : 'week') // Toggle between week and month view
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setIsWalkin(false)
  }

  const openBreakModal = () => {
    setIsBreakModalOpen(true)
  }

  const closeBreakModal = () => {
    setIsWalkin(false)
    setIsBreakModalOpen(false)
  }

  useEffect(() => {
    setStartDate(moment(selectedDate))
  }, [selectedDate])

  return (
    <>
      <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black', '--border-width': '0' }}>
        <IonLabel style={{ fontSize: fontSizes.size18, fontWeight: fontWeights.weightBold }}>{formatDateWithWeekDay(selectedDate)}</IonLabel>
        <IonButtons slot='start'>
          <IonButton>
            <IonMenuButton color={'primary'}></IonMenuButton>
          </IonButton>
        </IonButtons>
        <IonButtons slot='end'>
          {displayCalendar && (
            <IonButton onClick={handleView}>
              <IonIcon icon={calendarOutline} color='primary' />
            </IonButton>
          )}
          <IonButton onClick={openModal}>
            <IonIcon icon={add} color='primary' />
          </IonButton>
        </IonButtons>
        {loading && <IonProgressBar type='indeterminate' />}
      </IonToolbar>
      <>
        {view === 'week' ? (
          <div className='week-dates-container' style={{ margin: '0 auto' }}>
            <IonIcon style={{ marginLeft: '0.5rem' }} className='arrow-icon' icon={chevronBack} onClick={() => handlePrevWeek(setSelectedDate)} />
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
              {weekDays.map((day, index) => (
                <div
                  key={index}
                  className={`day-container ${index === 0 || index === weekDays.length - 1 ? 'grey-day' : ''}`}
                  style={{
                    backgroundColor: day.isSame(selectedDate, 'day') ? '#3880ff' : day.isSame(new Date(), 'day') ? '#d7d7d7' : '',
                  }}
                >
                  <IonLabel className={`date-label ${day.isSame(selectedDate, 'day') ? 'active-weekday-label' : 'weekday-label'}`}>
                    {day.format('ddd').slice(0, 2)}
                  </IonLabel>
                  <button onClick={() => handleClick(day)} className={`circle-button${day.isSame(selectedDate, 'day') ? ' active' : ''}`}>
                    <div className='date'>{day.format('D')}</div>
                  </button>
                </div>
              ))}
            </div>
            <IonIcon style={{ marginRight: '0.5rem' }} className='arrow-icon' icon={chevronForward} onClick={() => handleNextWeek(selectedDate)} />
          </div>
        ) : (
          <div className='month-dates-container' style={{ display: 'flex', justifyContent: 'center' }}>
            {}
            <IonDatetime
              mode='ios'
              presentation='day'
              value={date}
              onIonChange={(e) => {
                handleMonthDateChange(e.detail.value)
              }}
              style={{ color: 'black', background: 'white', minHeight: '20rem' }}
            />
          </div>
        )}
      </>

      <IonModal isOpen={isModalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={'Select Event'} closeButton={true} closeModal={closeModal} />
        <IonContent>
          <IonList>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
              }}
            >
              <IonIcon slot='start' icon={calendarOutline} />
              <IonLabel>Appointment</IonLabel>
            </IonItem>
            <IonItem
              detail={true}
              button
              onClick={() => {
                setApptModalOpen(true)
                setIsWalkin(true)
              }}
            >
              <IonIcon slot='start' icon={footstepsOutline} />
              <IonLabel>Walk-in</IonLabel>
            </IonItem>
            <IonItem detail={true} button onClick={openBreakModal}>
              <IonIcon slot='start' icon={cafeOutline} />
              <IonLabel>Break</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
      <IonModal isOpen={apptModalOpen}>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <StepController
              getAppointments={() => {
                refreshAppointments()
                closeModal()
              }}
              handlePrevWeek={handlePrevWeek}
              handleNextWeek={handleNextWeek}
              choosecustomer={true}
              isOpen={apptModalOpen}
              closeModal={() => {
                setApptModalOpen(false)
                setIsWalkin(false)
              }}
              isWalkin={isWalkin}
              setSubscriptionReferrer={() => {}}
            />
          </StandardContainer>
        </IonContent>
      </IonModal>
      <AddBreak selectedDate={selectedDate} handleSave={handleSaveBreak} employees={employees} open={isBreakModalOpen} onClose={closeBreakModal} />
      <IonToast
        isOpen={toast.isOpen}
        message={toast.message}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        position='top'
        duration={2000}
      />
    </>
  )
}

export default ListViewHeader
