import React, { useState, useRef } from 'react'
import StandardContainer from '../commonComponents/StandardContainer'
import { IonIcon, IonButton, IonLabel } from '@ionic/react'
import { imageOutline, addOutline, cameraOutline, trashBinOutline } from 'ionicons/icons'
import { colors } from '../theme/colors'

// Common styles
const uploadStyles = {
  width: '100%',
  height: '150px',
  border: '2px dashed grey',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  cursor: 'pointer',
}

const iconButtonStyles = {
  position: 'absolute',
  top: 10,
  right: 10,
  color: 'white',
  backgroundColor: colors.black15,
  fontSize: '18px',
  cursor: 'pointer',
  padding: 5,
}

const labelStyles = {
  position: 'absolute',
  top: 10,
  left: 10,
  color: 'white',
  backgroundColor: colors.black15,
  fontSize: '18px',
  cursor: 'pointer',
  padding: 5,
}

// Reusable ImageUpload component
const ImageUpload = ({ image, index, label, onDelete, onUpload }) => {
  return (
    <div style={{ width: '100%', maxWidth: index == 0 ? '100%' : '48%', position: 'relative' }}>
      <label htmlFor={`imageUpload-${index}`}>
        <div style={{ ...uploadStyles, height: index === 0 ? '250px' : '150px', backgroundImage: `url(${image})` }}>
          {!image && (
            <p style={{ textAlign: 'center', paddingTop: index === 0 ? '100px' : '50px' }}>
              {index === 0 ? 'Click to upload cover image' : <IonIcon style={{ color: 'grey', height: 30, width: 30 }} icon={imageOutline} />}
            </p>
          )}
        </div>
      </label>
      {image && (
        <>
          <IonIcon icon={trashBinOutline} style={iconButtonStyles} onClick={() => onDelete(index)} />
          {index === 0 && <IonLabel style={labelStyles}>Cover Photo</IonLabel>}
        </>
      )}
      <input id={`imageUpload-${index}`} type='file' style={{ display: 'none' }} accept='image/*' onInput={(e) => onUpload(e, index)} />
    </div>
  )
}

export default function Images({ location, updateLocation }) {
  const [images, setImages] = useState(Array(4).fill(null))
  const fileInputRef = useRef(null)
  const cameraInputRef = useRef(null)

  const fileToDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => resolve(event.target.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const updateImages = (newImages) => {
    setImages(newImages)
    const coverPhoto = newImages[0] || null
    const locationImages = newImages.slice(1).filter(Boolean)
    updateLocation({ ...location, coverPhoto, locationImages })
  }

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0]
    if (file) {
      const newImages = [...images]
      const dataUrl = await fileToDataUrl(file)
      newImages[index] = dataUrl
      updateImages(newImages)
    }
    e.target.value = null
  }

  const handleAddMoreImages = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const dataUrl = await fileToDataUrl(file)
      updateImages([...images, dataUrl])
    }
    e.target.value = null
  }

  const deleteImage = (index) => {
    const newImages = [...images]
    newImages.splice(index, 1)
    updateImages(newImages)
  }

  const hasImages = images.some((img) => img !== null)

  return (
    <StandardContainer padding={20}>
      {hasImages ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {/* Cover Image */}
          <ImageUpload image={images[0]} index={0} onDelete={deleteImage} onUpload={handleImageUpload} />

          {/* Location Images */}
          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {images.slice(1).map((img, index) => (
              <ImageUpload key={index} image={img} index={index + 1} onDelete={deleteImage} onUpload={handleImageUpload} />
            ))}

            {images.every((img) => img !== null) && (
              <div style={{ width: '100%', maxWidth: '48%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonButton
                  expand='block'
                  fill='clear'
                  color={'dark'}
                  style={{ border: '2px dashed grey', width: '100%', height: '150px', borderRadius: 10, justifyContent: 'center' }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <IonIcon slot='icon-only' icon={addOutline} />
                  <IonLabel>Add More</IonLabel>
                </IonButton>
                <input ref={fileInputRef} type='file' style={{ display: 'none' }} accept='image/*' onInput={handleAddMoreImages} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h2>Add some photos of the location.</h2>
          <IonLabel style={{ color: colors.black40 }}>You'll need at least one image to get started. You can add more or make changes later.</IonLabel>
          <div style={{ marginTop: 30 }}>
            {/* Add Photos */}
            <IonButton
              expand='block'
              fill='clear'
              color={'dark'}
              style={{ border: '1px solid grey', borderRadius: 10, margin: '10px 0' }}
              onClick={() => fileInputRef.current.click()}
            >
              <IonIcon slot='start' icon={addOutline} />
              <IonLabel>Add Photos</IonLabel>
            </IonButton>
            <input
              ref={fileInputRef}
              type='file'
              style={{ display: 'none' }}
              accept='image/*'
              multiple
              onInput={async (e) => {
                const files = Array.from(e.target.files)
                const newImages = await Promise.all(files.map(fileToDataUrl))
                updateImages([...newImages, ...images.slice(files.length)])
              }}
            />

            {/* Take Photos */}
            <IonButton
              expand='block'
              fill='clear'
              color={'dark'}
              style={{ border: '1px solid grey', borderRadius: 10, margin: '10px 0' }}
              onClick={() => cameraInputRef.current.click()}
            >
              <IonIcon slot='start' icon={cameraOutline} />
              <IonLabel>Take Photos</IonLabel>
            </IonButton>
            <input
              ref={cameraInputRef}
              type='file'
              style={{ display: 'none' }}
              accept='image/*'
              capture='camera'
              onInput={async (e) => {
                const file = e.target.files[0]
                if (file) {
                  const dataUrl = await fileToDataUrl(file)
                  updateImages([dataUrl, ...images.slice(1)])
                }
              }}
            />
          </div>
        </div>
      )}
    </StandardContainer>
  )
}
