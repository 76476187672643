import { useState, useContext, useEffect } from 'react'
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonLabel, IonTextarea, IonSpinner, IonToast } from '@ionic/react'
import axios from 'axios'
import 'react-quill/dist/quill.snow.css' // import the styles
import { AuthContext } from '../auth'
import StandardContainer from './StandardContainer'

function CustomerNotesModal({ isOpen, setOpen, customerId, fetchNotes, setNotes, notes, notesId, lastEditTime, lastEditorName }) {
  const { businessData } = useContext(AuthContext)
  const [isSaving, setSaving] = useState(false)
  const [isTextChanged, setTextChanged] = useState(false) // New state to track text changes
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })
  const showToastMessage = (message, color) => {
    setToast({ isOpen: true, message, color })
  }
  useEffect(() => {
    if (isOpen && customerId) {
      fetchNotes()
    }
  }, [isOpen, customerId])

  useEffect(() => {
    if (isOpen) {
      setTextChanged(false) // Reset the text changed flag when the modal is opened
    }
  }, [isOpen])

  const dismiss = () => {
    setOpen(false)
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      await axios.patch(`/customer_v2/updateNotes`, {
        _id: notesId,
        notes: notes,
      })
      fetchNotes()
      showToastMessage('Notes saved successfully!', 'success')

      dismiss()
    } catch (error) {
      showToastMessage('Failed to save notes. Please try again.', 'danger')
    } finally {
      setSaving(false)
    }
  }

  const handleNotesChange = (e) => {
    setNotes(e.detail.value)
    setTextChanged(true) // Set the text changed flag when the notes are modified
  }

  return (
    <>
      <IonModal isOpen={isOpen} trigger='open-modal' initialBreakpoint={1} breakpoints={[1]}>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonTitle style={{ color: 'black' }}>Customer Notes</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer medium={true}>
            <div style={{ backgroundColor: 'white' }}>
              <>
                <IonTextarea rows={30} fill='outline' style={{ padding: 10 }} value={notes} placeholder='Enter notes here...' onIonInput={handleNotesChange} />
                <IonItem>
                  <IonLabel style={{ fontSize: 10 }}>
                    Last Edit Time: {lastEditTime} <br />
                    Last Editor: {lastEditorName}
                  </IonLabel>
                </IonItem>
              </>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 1rem',
                marginTop: '1rem',
                position: 'absolute',
                bottom: '1rem',
                width: '100%',
              }}
            >
              <IonButton color={'dark'} style={{ width: '40%', textDecoration: 'underline' }} slot='start' fill='clear' onClick={dismiss}>
                Cancel
              </IonButton>
              {isTextChanged && ( // Only show the save button if the text has changed
                <IonButton style={{ width: '40%' }} color={'dark'} slot='end' onClick={handleSave} disabled={isSaving}>
                  {isSaving ? <IonSpinner name='dots' /> : <>Save</>}
                </IonButton>
              )}
            </div>
          </StandardContainer>
        </IonContent>
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        duration={2000}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
      />
    </>
  )
}

export default CustomerNotesModal
