import { IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from '@ionic/react'
import { businessOutline } from 'ionicons/icons'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../auth'
import { roles } from '../../StaticData/StaticData'
export default function EachLocationPermission({
  updateEntitlements,
  entitlementsData,
  location_id,
  user,
}) {
  const { locationData, businessData } = useContext(AuthContext)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [noPermissions, setNoPermissions] = useState(true)
  const [updatingPermissions, setUpdatingPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [selectedPermissions, setSelectedPermissions] = useState({
    admin: false,
    manager: false,
    professional: false,
  })
  const [locationDetails, setLocationData] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleupdatingPermissions = (value, permission) => {
    // Clone the object to avoid mutating the state directly
    const updatedPermissions = { ...updatingPermissions }

    // Set only the specified permission to true, others to false
    Object.keys(updatedPermissions).forEach(key => {
      updatedPermissions[key] = key === permission ? value : false
    })

    // Set the updated object in the state
    setUpdatingPermissions(updatedPermissions)
  }

  const getLocationDetails = async () => {
    if (location_id) {
      setLoading(true)
      try {
        let response = await axios.get(`/location_v2?id=${location_id}`)
        if (response && response.status == 200) {
          setLocationData(response.data)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
      }
    }
  }
  // const getLocationEntitlements = () => {
  //   const location = user?.businesses
  //     .flatMap(business => business.locations)
  //     .find(loc => loc._id === location_id)

  //   return location ? location.entitlements : null
  // }
  // const updateEntitlements = () => {
  //   // Use the function to update the selectedPermissions state
  //   const updatedPermissions = getLocationEntitlements()

  //   if (updatedPermissions) {
  //     setSelectedPermissions(updatedPermissions)
  //   } else {
  //     console.error('Location not found in the business array')
  //   }
  // }
  useEffect(() => {
    // updateEntitlements()
    getLocationDetails()
  }, [])
  useEffect(() => {
    if (entitlementsData?.locations) {
      const location = entitlementsData.locations.find(loc => loc._id === location_id)

      if (location) {
        setIsAdmin(location.entitlements.admin)
        setIsManager(location.entitlements.manager)
        setIsProfessional(location.entitlements.professional)
        setNoPermissions(location.entitlements.noPermissions)
      }
    }
  }, [entitlementsData, location_id])

  useEffect(() => {
    getLocationDetails()
  }, [location_id])
  const handlePermission = async permission => {
    const updatedLocations = entitlementsData.locations.map(location => {
      if (location._id === location_id) {
        // If the location matches the specified location_id
        return {
          ...location,
          entitlements: {
            ...location.entitlements,
            // Toggle the value of the target permission
            admin: false,
            manager: false,
            professional: false,
            noPermissions: false,
            [permission]: !location.entitlements[permission],
            // Set other permissions to false
          },
        }
      } else {
        // If the location does not match the specified location_id, keep it unchanged
        return location
      }
    })

    // Create a new object with the updated locations
    const updatedData = {
      ...entitlementsData,
      locations: updatedLocations,
    }

    // Perform any additional logic or state updates with the updatedData if needed
    // ...

    updateEntitlements(updatedData)
    // If needed, you can set the updated data to state or send it to the server
    // setEntitlementsData(updatedData);
    // await sendUpdatedDataToServer(updatedData);
  }

  return (
    <IonCard style={{ padding: '10px' }}>
      {loading && <IonSpinner name='dots' />}
      {!loading && (
        <IonGrid>
          <IonRow style={{ display: 'flex', alignItems: 'end' }}>
            <IonIcon style={{ marginRight: '10px' }} icon={businessOutline} size='large' />
            <h6 style={{ margin: '0', color: 'black', fontWeight: 'bold' }}>
              {locationDetails.name} Permissions
            </h6>
          </IonRow>
          <IonRow style={{ marginTop: '1rem' }}>
            {roles.map((item, index) => {
              return (
                <IonRow>
                  <IonCol size='auto'>
                    <h6
                      style={{
                        margin: '5px',
                        fontWeight: 'bold',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {item.role}
                    </h6>
                    <IonRow onClick={() => handlePermission(item.value)}>
                      <IonCol size='10'>
                        <h6 style={{ margin: '5px', fontSize: '12px' }}>{item.description}</h6>
                      </IonCol>
                      <IonCol>
                        {updatingPermissions[item.value] ? (
                          <IonSpinner name='lines' />
                        ) : (
                          <IonCheckbox
                            style={{ height: '1.5rem', width: '1.5rem' }}
                            checked={
                              item.value === 'admin'
                                ? isAdmin
                                : item.value === 'manager'
                                ? isManager
                                : item.value === 'professional'
                                ? isProfessional
                                : item.value === 'noPermissions'
                                ? noPermissions
                                : false
                            }
                          />
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              )
            })}
          </IonRow>
        </IonGrid>
      )}
    </IonCard>
  )
}
