import { useContext, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonList, IonModal, IonPopover, IonToast } from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'
import CommonHeader from '../../commonComponents/CommonHeader'
import { limitDecimalPlaces } from '../../utils'
import axios from 'axios'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import ConfirmationModal from '../../commonComponents/ConfirmationModal'
import CancelAppointmentForm from './CancelAppointmentForm'

let styles = {
  ionListItem: {
    marginTop: 4,
    marginBottom: 4,
  },
}

function ActionMenu({ appointment, payedExternal, status, updateStatus, userData, cancelAppointment, getAppointment }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [defaultChargeEnabled, setDefaultChargeEnabled] = useState(false)
  const [customChargeValue, setCustomChargeValue] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const { locationData, locationId, getBusinessData } = useContext(AuthContext)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const locationCancelationSettings = selectedLocationData?.appointmentSettings?.cancellationSettings

  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)

  const cancellationAllowed = timeDifferenceInHours > 24
  const handleCustomChargeValue = (value) => {
    setCustomChargeValue(value)
    if (value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }
  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationCancelationSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationCancelationSettings?.value
    } else if (locationCancelationSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationCancelationSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const handleCancelAppointment = async () => {
    setLoading(true)
    let cancelationCharge = 0
    let customizedCancelationCharge = !defaultChargeEnabled

    if (locationCancelationSettings?.charge && cardOnFile) {
      cancelationCharge = cancellationAllowed ? 0 : parseFloat(defaultChargeEnabled ? defaultCancellationCharges : customChargeValue)
    } else {
      cancelationCharge = 0
      customizedCancelationCharge = false
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'Canceled',
        paidExternal: true,
        cancelationCharge,
        customizedCancelationCharge,
      }

      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithCancelationCharge = {
        ...appointment?.payment,
        cancelationCharge,
      }

      await axios.patch(`/appointment_v2?id=${appointment?._id}`, {
        payment: paymentObjectWithCancelationCharge,
      })
      setToast({ isOpen: true, message: 'Appointment canceled successfully.', color: 'success' })
      await getAppointment()
      closeModal()
    } catch (error) {
      setToast({ isOpen: true, message: 'Error canceling appointment. Please try again later.', color: 'danger' })

      // Handle error
    }

    setLoading(false)
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customChargeValue, 2)
    setCustomChargeValue(formattedValue)
  }, [customChargeValue])
  const openModal = () => {
    setModalOpen(true)
    getBusinessData()
  }
  const getCard = async () => {
    try {
      let response = await axios.get(`/customer_v2/cardStripe?customerId=${appointment?.customer?._id}`)
      setCardOnFile(response?.data?.cardOnFile || false)
    } catch (error) {
      console.error('Error fetching card information:', error)
    }
  }
  useEffect(() => {
    getCard()
  }, [])

  const closeModal = () => {
    getBusinessData()
    setDefaultChargeEnabled(false)
    setCustomChargeValue(false)
    setError(false)
    setLoading(false)
    setModalOpen(false)
  }
  const toggleDefaultCharge = () => {
    setDefaultChargeEnabled(!defaultChargeEnabled)
    setCustomChargeValue('')
    setError('')
  }
  if (['Complete'].includes(status)) return null

  return (
    <>
      <IonButtons slot='end' style={{ color: 'black' }}>
        <IonButton id='popover-button' fill='clear'>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
        <IonPopover trigger='popover-button' dismissOnSelect={true}>
          <IonContent>
            <IonList>
              {['In Progress'].includes(status) ? (
                <IonItem button={true} detail={false} onClick={payedExternal} style={styles.ionListItem}>
                  Paid External - Complete
                </IonItem>
              ) : null}

              {['Complete', 'Scheduled'].includes(status) ? null : (
                <IonItem
                  button={true}
                  detail={false}
                  onClick={() => {
                    updateStatus('Scheduled')
                  }}
                  style={styles.ionListItem}
                >
                  Change Status Back to "Scheduled"
                </IonItem>
              )}
              {!['Complete'].includes(status) && status != 'Canceled' ? (
                <>
                  {userData?.entitlements?.manager || userData?.entitlements?.admin ? (
                    <>
                      <IonItem
                        button={true}
                        detail={false}
                        onClick={() => {
                          openModal()
                        }}
                      >
                        Cancel Appointment
                      </IonItem>
                    </>
                  ) : null}
                </>
              ) : null}
            </IonList>
          </IonContent>
        </IonPopover>
        <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
          <CommonHeader title={'Cancel Appointment'}></CommonHeader>
          {/* if customer doesnot have card on file,or if cancellation is allowed or if the settings are not enabled then show a default popup. */}
          {cancellationAllowed || !cardOnFile || !locationCancelationSettings?.charge ? (
            <ConfirmationModal
              message={'Are you sure you want to cancel this appointment'}
              cancel={() => closeModal()}
              loading={loading}
              save={() => handleCancelAppointment()}
              cancelText={'Dismiss'}
              saveText={'Cancel Appointment'}
            />
          ) : (
            // else show a cancellation form where they can choose a default charges from the configuration or add a custom charge
            <CancelAppointmentForm
              defaultCancellationCharges={defaultCancellationCharges}
              defaultChargeEnabled={defaultChargeEnabled}
              toggleDefaultCharge={toggleDefaultCharge}
              appointment={appointment}
              customChargeValue={customChargeValue}
              handleCustomChargeValue={handleCustomChargeValue}
              error={error}
              closeModal={closeModal}
              loading={loading}
              handleCancelAppointment={handleCancelAppointment}
            />
          )}
        </IonModal>
      </IonButtons>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </>
  )
}
export default ActionMenu
