import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import React from 'react'
import { numberWithCommas } from '../utils'
import StandardCenterCenter from '../commonComponents/StandardCenterCenter'

export default function StatItems({ stats, month, year, subscriberAverage }) {
  return stats ? (
    <IonList inset={true} mode='ios'>
      <IonItem key='Info'>
        <IonLabel>
          <strong>Appointments Completed</strong>
        </IonLabel>
        <IonLabel slot='end'>{stats?.countAppointments || 0}</IonLabel>
      </IonItem>
      <IonItem key='AvgTick2'>
        <IonLabel>
          <strong>Blended Service Value (less tips)</strong>
        </IonLabel>
        <IonLabel slot='end'>
          $
          {(
            ((stats?.totalPrice || 0) + (stats?.mrr || 0)) - (stats?.totalTips || 0) /
            (stats?.countAppointments || 0)
          ).toFixed(2)}
        </IonLabel>
      </IonItem>

      <IonItem key='Prod'>
        <IonLabel>
          <strong>Value of Products</strong>
        </IonLabel>
        <IonLabel slot='end'>${stats?.totalValueofProducts || 0}</IonLabel>
      </IonItem>
      <IonItem key='Tip'>
        <IonLabel>
          <strong>Total Tips</strong>
        </IonLabel>
        <IonLabel slot='end'>${numberWithCommas(stats?.totalTips || 0)}</IonLabel>
      </IonItem>
      <IonItem key='AvgTip'>
        <IonLabel>
          <strong>Average Tip</strong>
        </IonLabel>
        <IonLabel slot='end'>
          ${((stats?.totalTips || 0) / (stats?.countAppointments || 0)).toFixed(2)}
        </IonLabel>
      </IonItem>
      <IonItem key='SubSold'>
        <IonLabel>
          <strong>Subscriptions Sold</strong>
        </IonLabel>
        <IonLabel slot='end'>{stats?.countSubscriptionsSold}</IonLabel>
      </IonItem>
      <IonItem key='SubsCanceled'>
        <IonLabel>
          <strong>Subscriptions Canceled</strong>
        </IonLabel>
        <IonLabel slot='end'>{stats?.countOfSubscriptionsCanceled}</IonLabel>
      </IonItem>
      {/* <IonItem key='MRR'>
        <IonLabel>
          <strong>Estimated MRR</strong>
        </IonLabel>
        <IonLabel slot='end'>${numberWithCommas(stats?.mrr)}</IonLabel>
      </IonItem> */}
      <IonItem key='AppPerSub'>
        <IonLabel>
          <strong>Avg Appt per Sub</strong>
        </IonLabel>
        <IonLabel slot='end'>
          {subscriberAverage.toFixed(2)} (
          {(new Date(year, month, 0).getDate() / subscriberAverage / 7).toFixed(1)} Weeks)
        </IonLabel>
      </IonItem>
    </IonList>
  ) : (
    <StandardCenterCenter>
      <div style={{ margin: 50 }}>
        <IonSpinner name='dots' />
      </div>
    </StandardCenterCenter>
  )
}
